<script setup>
import { ref } from "@vue/reactivity";
import router from "@/router";
import { onMounted, onUpdated, onBeforeUnmount } from "@vue/runtime-core";
import Swal from "sweetalert2";
import loadercomp from "@/components/loadercomp";
import ErrorComp from "@/components/errormessage.vue";
import getCookie from "@/scripts/utils/getCookie.js";
import setActiveOrg from "@/scripts/dashboard_functs/setActiveOrg";
import { useStore } from "vuex";
const store = useStore();
const org_name = ref(null);
const promo_code = ref(null);
let referral_code = ref(null);
const errMsg = ref(null);

onBeforeUnmount(() => {
    fetch("/api/account")
        .then((res) => {
            return res.json();
        })
        .then((result) => {
            store.state.username = result.username;
            store.state.orgs = result.orgs;
            store.state.email = result.email;
            store.state.isweb3 = result.isweb3;
            setActiveOrg(result.orgs[0]);
        });
});
onMounted(() => {
    referral_code.value = window.localStorage.getItem("referral_code");
});
onUpdated(() => {
    if (errMsg.value) {
        setTimeout(() => (errMsg.value = null), 5000);
    }
});

const createOrg = async () => {
    store.commit("toggleLoader");
    promo_code.value === true ? promo_code.value.toUpperCase : null;
    const response = await fetch("/api/org/new", {
        method: "POST",
        body: JSON.stringify({
            org: org_name.value,
            promo_code: promo_code.value,
            referral_code: referral_code.value,
        }),
        headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": getCookie("csrftoken"),
        },
    });

    const data = await response.json();
    if (data) {
        store.commit("toggleLoader");
    }
    if (data.ok) {
        if (typeof window.dataLayer == "object") {
            window.dataLayer.push({ event: "web3-signup-complete" });
        }
        Swal.fire({
            icon: "success",
            titleText: "Setup Complete",
            text: `
                    
                    `,
            confirmButtonColor: "#417dd8",
            confirmButtonText: "Continue to Dashboard",
        });
        router.push({ name: "overview" });
    } else if (data.error == "bad-promo") {
        errMsg.value = "The promo code you entered is not available. Double check and try again";
    } else {
        errMsg.value = "An unknown error has occurred";
    }
};
</script>

<template>
    <div class="wrap dashboard_root">
        <loadercomp v-if="loader" />
        <h2 class="ta_center">ORGANIZATION SETUP</h2>
        <form @submit.prevent="createOrg" class="flex flex_col">
            <p class="ta_center">Please provide a name for your organization or project</p>
            <ErrorComp :errMsg="errMsg" className="error" v-if="errMsg" />
            <input required v-model="org_name" type="text" placeholder="Enter Name " />
            <input v-model="promo_code" type="text" placeholder="Promo code (if available)" />
            <div class="btn_color btn_long btn ta_center">
                <button type="submit">Complete</button>
            </div>
        </form>
    </div>
</template>

<style lang="scss" scoped>
.wrap {
    margin: 0 auto;
    form {
        width: 25rem;
        gap: 1.3rem;
        margin: 0 auto;
        input {
            padding: 15px;
            font-family: "Sora";
            border-radius: 5px;
            outline: none;
            border: 1px solid #070707;
        }
        @include media("<=phone-tab") {
            width: 100%;
        }
    }
}
</style>
